<template>
<div>
  <b-container fluid class="mb-3" v-if="!loading">
    <b-row>
      <b-col>
        <h4 class="card-title">{{item.name}}</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5 class="card-title">Metrics</h5>
        <div class="my-4">
          <treemap-simple :data="tree" :name="''" :width="'100%'" :height="'60px'"></treemap-simple>
        </div>
        <search-interface namespace="metrics" :rows="metrics" v-model="rowsSearched"></search-interface>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
        <div class="mb-3" v-for="(item, index) in showRowsPage" :key="'metric-'+ item.id + '-' + index">
          <line-item :item="item" :trim="400"></line-item>
        </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
      </b-col>
      <b-col>
        <h5 class="card-title">Data elements</h5>
        <div class="mb-4" style="display: flex; height: 30px;">
          <search-interface namespace="dataelements" :rows="dataelements" v-model="dataelementsSearched"></search-interface>
        </div>
        <b-pagination
          inline
          v-model="currentPageDE"
          :total-rows="dataelementsSearched.length"
          :per-page="perPageDE"
          class="mt-3"
        ></b-pagination>
        <div class="mb-3" v-for="item in dataelementsSearchedPage" :key="'dataelement-' + item.id">
          <line-item-data :item="item" :trim="400"></line-item-data>
        </div>
        <b-pagination
          inline
          v-model="currentPageDE"
          :total-rows="dataelementsSearched.length"
          :per-page="perPageDE"
          class="mt-3"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import delay from '@/libs/delay'
import LineItem from '@/views/metrics/MetricSimple.vue'
import LineItemData from '@/views/metrics/DataElementSimple.vue'
import TreemapSimple from '@/components/charts/v1/treemapSimple.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'

function countUniqueValues (array) {
  // Count the occurrences of each value
  const counts = _.countBy(array)
  // Transform the counts object into an array of objects with name and count properties
  const result = _.map(counts, (value, name) => ({ name, value }))
  return result
}

export default {
  name: 'DataElement',
  components: {
    LineItem,
    LineItemData,
    TreemapSimple,
    SearchInterface
  },
  computed: {
    metricsAll: function () {
      return this.$store.state.metrics
    },
    reportingframeworks: function () {
      return this.$store.state.reportingframeworks
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.rowsSearched.slice(start, end)
    },
    dataelementsSearchedPage: function () {
      const start = (this.currentPageDE - 1) * this.perPageDE
      const end = this.currentPageDE * this.perPageDE
      return this.dataelementsSearched.slice(start, end)
    }
  },
  created: function () {
    this.load()
  },
  data () {
    const data = {
      dataelements: [],
      dataelementsSearched: [],
      item: {},
      loading: true,
      metrics: [],
      perPage: 10,
      perPageDE: 20,
      currentPage: 1,
      currentPageDE: 1,
      rowsFiltered: [],
      rowsSearched: [],
      rowsSorted: [],
      sortProperties: [
        { value: 'name', text: 'Name' }
      ],
      sunburst: [],
      tree: []
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      await delay(200)
      this.item = this.reportingframeworks.find(item => item.id === Number.parseInt(this.$route.params.id))
      // find all metrics where metric.reportingframeworks contains a record with id === this.$route.params.id
      const metrics = this.metricsAll.filter(metric => metric.reportingframeworks.find(framework => framework.id === Number.parseInt(this.$route.params.id)))
      this.metrics = _.orderBy(_.uniqBy(metrics, 'id'), ['name'], ['asc'])
      const environmentals = []
      const governance = []
      const social = []
      this.metrics.forEach(metric => {
        metric.dataelements.forEach(element => {
          this.dataelements.push(element)
        })
        metric.taxonomy.forEach(taxonomy => {
          taxonomy.parents.forEach(parent => {
            if (parent.name === 'Environmental') {
              environmentals.push(taxonomy.name)
            }
            if (parent.name === 'Governance') {
              governance.push(taxonomy.name)
            }
            if (parent.name === 'Social') {
              social.push(taxonomy.name)
            }
          })
        })
      })
      this.sunburst = [
        { name: 'Environmental', children: countUniqueValues(environmentals) },
        { name: 'Governance', children: countUniqueValues(governance) },
        { name: 'Social', children: countUniqueValues(social) }
      ]
      this.tree = [
        { name: 'Environmental', value: environmentals.length },
        { name: 'Governance', value: governance.length },
        { name: 'Social', value: social.length }
      ]
      this.dataelements = _.orderBy(_.uniqBy(this.dataelements, 'id'), ['name'], ['asc'])
      await delay(50)
      this.loading = false
    }
  },
  watch: {
    '$route': function () {
      this.load()
    }
  }
}
</script>
